import Swiper from 'swiper'
import { Navigation, Pagination, Parallax, Autoplay, EffectFade, Scrollbar } from 'swiper/modules'

export default (config) => ({
  init () {
    const SwiperDefaultOptions = {
      modules: [Navigation, Pagination, Parallax, Autoplay, EffectFade, Scrollbar],
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next
      }
    }
    
    const swiper = new Swiper(this.$refs.slider, {
      ...SwiperDefaultOptions,
      ...config
    })
  }
})