import { Livewire, Alpine } from '~/vendor/livewire/livewire/dist/livewire.esm'

import ui from '@alpinejs/ui'
import focus from "@alpinejs/focus"
import anchor from "@alpinejs/anchor"
import collapse from "@alpinejs/collapse"

/**
 * Custom Plugins
 */
import emerge from "./_emerge"

/**
 * Stores
 */
import { default as header } from './_header'

/**
 * Reusable Data Objects
 */
import { default as slider } from './_slider'


Alpine.plugin(ui)
Alpine.plugin(focus)
Alpine.plugin(anchor)
Alpine.plugin(collapse)

Alpine.plugin(emerge)

Alpine.store('header', header)

Alpine.data('slider', slider)

Livewire.start()

export default Alpine