// https://tailwindcss.com/docs/configuration

const plugin = require("tailwindcss/plugin");

module.exports = {
  content: [
    "./index.php",
    "./app/**/*.php",
    "./resources/**/*.{php,vue,js}",
    "!./resources/blocks/*.js",
    "./config/acf.php",
    "./templates/**/*.html",
  ],
  corePlugins: {
    container: false,
  },
  theme: {
    fontFamily: {
      sans: ["proxima-nova", "sans-serif"],
    },
    fontSize: {
      "2xs": [
        "0.625rem",
        {
          lineHeight: 1.2,
          letterSpacing: "0.02em",
        },
      ],
      xs: [
        "0.75rem",
        {
          lineHeight: 1.2,
          letterSpacing: "0.02em",
        },
      ],
      sm: [
        "0.8125rem",
        {
          lineHeight: 1.5,
          letterSpacing: "normal",
        },
      ],
      base: [
        "1rem",
        {
          lineHeight: 1.4,
        },
      ],
      lg: [
        "1.125rem",
        {
          lineHeight: 1.4,
        },
      ],
      xl: [
        "1.25rem",
        {
          lineHeight: 1.5,
          letterSpacing: "0.01em",
        },
      ],
      "2xl": [
        "1.5rem",
        {
          lineHeight: 1.5,
          letterSpacing: "0.01em",
        },
      ],
      "3xl": [
        "1.75rem",
        {
          lineHeight: 1.2,
          letterSpacing: "0.01em",
        },
      ],
      "4xl": [
        "2rem",
        {
          lineHeight: 1,
          letterSpacing: "0.01em",
        },
      ],
      "5xl": [
        "2.625rem",
        {
          lineHeight: 0.9,
        },
      ],
      "6xl": [
        "3rem",
        {
          lineHeight: 0.9,
        },
      ],
      "7xl": [
        "4.0625rem",
        {
          lineHeight: 0.8,
        },
      ],
      "8xl": [
        "6.4375rem",
        {
          lineHeight: 0.8,
        },
      ],
      "9xl": [
        "10.1875rem",
        {
          lineHeight: 0.8,
        },
      ],
    },
    extend: {
      backgroundImage: {
        phone:
          "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8BAMAAADI0sRBAAAAJFBMVEUAAACNxz+Nxz+Nxz+Nxz+Nxz+Nxz+Nxz+Nxz+Nxz+Nxz+Nxz/9IYgmAAAAC3RSTlMAv4DfjxCv78+fIAEqtH0AAABfSURBVDjLYxj+wHs3HGwJwJBl2Y0EHDCkuUHChYKCwiB6A+nSUECe9EYEW3pUelR6VHqISW9WggNr8kqHzQtYs/FIb2Jg0KaZ9A4GBhx2cyK7XABDmhWpyN0WwDDcAQA3GqbIc1KdPwAAAABJRU5ErkJggg==)",
      },
      colors: {
        primary: "#8cc73f",
        "primary-dark": "#73ae35",
        black: "#3C3C3C",
        gray: {
          50: "#f7f7f7",
          100: "#EEEEEE",
          200: "#DDDDDD",
          700: "#424242",
        },
      },
      typography: ({ theme }) => ({
        DEFAULT: {
          css: {
            "--tw-prose-body": "currentColor",
            "--tw-prose-headings": "currentColor",
            "--tw-prose-lead": "currentColor",
            "--tw-prose-links": "currentColor",
            "--tw-prose-bold": "currentColor",
            "--tw-prose-counters": "currentColor",
            "--tw-prose-bullets": theme("colors.primary"),
            "--tw-prose-hr": "currentColor",
            "--tw-prose-quotes": "currentColor",
            "--tw-prose-quote-borders": "currentColor",
            "--tw-prose-captions": "currentColor",
            "--tw-prose-code": "currentColor",
            "--tw-prose-pre-code": "currentColor",
            "--tw-prose-pre-bg": "currentColor",
            "--tw-prose-th-borders": "currentColor",
            "--tw-prose-td-borders": "currentColor",

            a: {
              textDecorationColor: theme("colors.primary"),

              "&:hover": {
                color: theme("colors.primary"),
              },
            },

            blockquote: {
              paddingInlineStart: 0,
              borderInlineStartWidth: 0,
              fontStyle: "normal",

              p: {
                marginTop: 0,
                marginBottom: "2rem",
              },
              "& p:before": {
                display: "none",
              },

              "& p:after": {
                display: "none",
              },
            },
            cite: {
              fontStyle: "normal",
            },
            ul: {
              paddingInlineStart: "0.5rem",
              
              li: {
                "&::marker": {
                  content: '"• " !important',
                  display: "inline",
                  color: theme("colors.primary"),
                  lineHeight: 0.8,
                },
              }
            },
            li: {
              marginTop: 0,
              marginBottom: 0,
              lineHeight: 1.2,

              div: {
                marginTop: 0,
                marginBottom: 0,
              },
            },
          },
        },
        sm: {
          css: {
            ul: {
              listStyle: "none",
              paddingInlineStart: 0,
            },
            li: {
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: 0,
            },
          },
        },
        base: {
          css: {
            ul: {
              listStyle: "none",
              paddingInlineStart: 0,
            },
            li: {
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: 0,
            },
          },
        },
      }),
    },
  },
  plugins: [
    require("@tailwindcss/typography"),
    plugin(require("./tw-forms.config.js")),
    plugin(function ({ addVariant }) {
      addVariant("menu", [
        "& .wp-block-navigation__container",
        "& .wp-block-navigation > ul",
      ]);
      addVariant(
        "menu-item",
        "& > .wp-block-navigation > ul > .wp-block-navigation-item"
      );
      addVariant("menu-link", [
        "& > .wp-block-navigation ul.wp-block-navigation__container > .wp-block-navigation-item > a",
        "& .wp-block-navigation-item__content > div",
      ]);
      addVariant(
        "submenu",
        "& > .wp-block-navigation > ul > .wp-block-navigation-item .wp-block-navigation-submenu"
      );
      addVariant(
        "submenu-trigger",
        "& > .wp-block-navigation > ul > .wp-block-navigation-item .wp-block-navigation-submenu__toggle"
      );
      addVariant(
        "submenu-item",
        "& > .wp-block-navigation > ul > .wp-block-navigation-item .wp-block-navigation-submenu .wp-block-navigation-item"
      );
      addVariant(
        "submenu-link",
        "& > .wp-block-navigation > ul > .wp-block-navigation-item .wp-block-navigation-submenu a"
      );
    }),
  ],
};
